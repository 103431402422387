<template>
  <div>
    <div>
      <CDataTable
          hover
          striped
          border
          :itemsPerPage="200"
          :items="itemMasters"
          :fields="fields"
      >
        <td slot="item_id" slot-scope="{item}">
          <div>
            {{ item.item_id }}
          </div>
        </td>
<!--        <td slot="arrived_at" slot-scope="{item}">-->
<!--          <div>-->
<!--            {{ item.item.stock.instock_date }}-->
<!--          </div>-->
<!--        </td>-->
<!--        <td slot="shelf" slot-scope="{item}">-->
<!--          <div>-->
<!--            {{ item.item.stock.shelf.name }}-->
<!--          </div>-->
<!--        </td>-->
        <td slot="item_code" slot-scope="{item}" class="text-center">
          <div>
            {{ item.item.item_master.item_code }}
          </div>
        </td>
        <td slot="name" slot-scope="{item}" class="text-center">
          <div>
            {{ item.item.item_master.name }}
          </div>
        </td>
        <td slot="size" slot-scope="{item}" class="text-center">
          <div>
            {{ item.item.item_master.size.name }}
          </div>
        </td>
        <td slot="color" slot-scope="{item}" class="text-center">
          <div>
            {{ item.color_name }}
          </div>
        </td>
        <td slot="cost" slot-scope="{item}" class="text-right">
          <div>
            {{ item.cost }}
          </div>
        </td>
        <td slot="price" slot-scope="{item}" class="text-right">
          <div>
            {{ item.price }}
          </div>
        </td>
        <td slot="retail_price" slot-scope="{item}" class="text-right">
          <div>
            {{ item.retail_price }}
          </div>
        </td>
      </CDataTable>
    </div>
  </div>
</template>
<script>
import apiCardboardOther from "@/api/cardboardApi";
import {numberWithCommas} from "@/utils/utils";

export default {
  props: {
    screen_key: String,
    cardboard: Number,
  },
  data() {
    return {
      itemMasters: [],
      fields: [
        {key: 'status_name', label: 'ステータス'},
        {key: 'item_id', label: '商品ID'},
        // {key: 'arrived_at', label: '入荷日'},
        // {key: 'shelf', label: '棚名'},
        {key: 'item_code', label: '品番'},
        {key: 'name', label: '商品名'},
        {key: 'size', label: 'サイズ'},
        {key: 'color', label: '色'},
        {key: 'cost', label: '仕入原価'},
        {key: 'price', label: '販売価格'},
        {key: 'retail_price', label: 'プロパー上代'},
      ],
      cardboardId: this.cardboard,
      screenKey: this.screen_key,
    }
  },
  watch: {
    screen_key: function (newVal, oldVal) {
      console.log(newVal)
      this.itemMasters = []
      this.screenKey = newVal
      this.getData(this.cardboardId, this.screenKey)
    },
    cardboard: function (newVal, oldVal) {
      this.itemMasters = []
      this.cardboardId = newVal
      this.getData(this.cardboardId, this.screenKey)
      this.count = this.count + 1
    },
  },
  methods: {
    async getData(cardboardId, screenKey) {
      console.log()
      let params = {cardboard_id: cardboardId, screen_key: screenKey}
      await apiCardboardOther.getItemByStatus(params).then(response => {
        let data = response.data
        this.itemMasters = data
        this.itemMasters.map(item => {
          item.cost = numberWithCommas(item.item.item_master.cost)
          item.price = item.item.stock.price ? numberWithCommas(item.item.stock.price) : numberWithCommas(item.item.item_master.price)
          item.retail_price = numberWithCommas(item.item.item_master.retail_price)
          item.color_name = item.item.item_master.color.color_code + ':' + item.item.item_master.color.name
        })
        // this.mapItemMaster(data)
      }).catch(errror => {

      })
    },
    mapItemMaster(items) {
      items.map(data => {
            let item = {
              status: data.status_name,
              cardboard_id: data.cardboard_id,
              id: data.id,
              item_id: data.item_id,
              arrived_at: data.item.stock.instock_date,
              shelf: data.item.stock.shelf.name,
              item_code: data.item.item_master.item_code,
              name: data.item.item_master.name,
              size: data.item.item_master.size,
              color: data.item.item_master.color,
              cost: numberWithCommas(data.item.item_master.cost),
              price: numberWithCommas(data.item.item_master.price),
              retail_price: numberWithCommas(data.item.item_master.retail_price)
            }
            this.itemMasters.push(item)
          }
      )
    },
  }
}
</script>