<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          {{ cardHeaderTitle }}
        </CCardHeader>
        <vue-confirm-dialog></vue-confirm-dialog>

        <CCardBody>
          <ModalComponent
              title="商品一覧"
              color="warning"
              size="xl"
              :show.sync="showItemModal"
              :footer=false
          >
            <ListItemCardBoard :screen_key="screenKeyItem" :cardboard="cardboardId"></ListItemCardBoard>
          </ModalComponent>
          <div>
            <CRow>
              <CCol sm="2">
                <CSelect
                    id="Date"
                    v-model="typeDate"
                    :options="typeDateOpitons"
                    @change="selectChangeTypeDate"
                    horizontal
                    class="typeDate"
                >
                </CSelect>
              </CCol>
              <CCol sm="3" style="padding-left: 0">
                <CInput
                    id="filterDate"
                    type="date"
                    horizontal
                    v-model="filterDate"
                    @change="changeFilterDate"
                />
              </CCol>
            </CRow>
            <!--            <CRow>-->
            <!--              <CCol sm="4">-->
            <!--                <CInput-->
            <!--                    label="Created At"-->
            <!--                    type="date"-->
            <!--                    horizontal-->
            <!--                    v-model="filterCreatedAt"-->
            <!--                />-->
            <!--              </CCol>-->
            <!--            </CRow>-->

            <CRow class="mb-3">
              <CCol sm="12">
                <!--                <CButton color="primary" class="mr-2" @click="filterData">検索</CButton>-->
                <CButton color="danger" @click="resetFilterData">取り消す</CButton>
              </CCol>
            </CRow>
          </div>
          <CRow class="text-right mb-2">
            <CCol sm="8" style="display: flex;justify-content: flex-end;align-items: center;">
              <CCol sm="4" v-if="screen_key === SCREEN_KEY.LIST_CARDBOARD">
                <CSelect
                    id="cardboardStatus"
                    v-model="statusChange"
                    :options="statusListOpitons"
                    @change="changeStatusChange"
                    horizontal
                    class="statusChange"
                ></CSelect>
              </CCol>
              <CCol sm="4" v-if="screen_key === SCREEN_KEY.LIST_CARDBOARD_INSPECT">
                <CSelect
                    id="cardboardStatusInspect"
                    v-model="statusChange"
                    :options="statusInspectOpitons"
                    @change="changeStatusChange"
                    horizontal
                    class="statusChange"
                ></CSelect>
              </CCol>
              <CButton color="primary" class="mr-2" @click="handleConfirmChangeStatus">変更する</CButton>
            </CCol>
            <CCol sm="4" v-if="screen_key === SCREEN_KEY.LIST_CARDBOARD">
<!--              <CButton color="primary" class="mr-2" @click="handleQRDownload">QR印刷</CButton>-->
              <CButton color="primary" class="mr-2" @click="renderCreateCardboard">{{ cardRegistrationBtn }}</CButton>
            </CCol>
<!--            <CCol sm="4" v-if="screen_key === SCREEN_KEY.LIST_CARDBOARD_INSPECT">-->
<!--              <CButton color="primary" @click="renderQrCode">スキャンして検品</CButton>-->
<!--            </CCol>-->
          </CRow>

          <div v-if="loading">
            <LoadingCompoment/>
          </div>
          <div v-else :key="key">
            <DataTable
                hover
                striped
                border
                :items="cardboards"
                :fields="fields"
                :items-per-page="perPage"
                :active-page="page"
                :pagination-data="paginationData"
                @page-change="pageChange"
                @pagination-change="selectChangePerpage"
                :pagination="{ doubleArrows: false, align: 'center'}"
                :items-per-page-select="{
                  label: '表示件数',
                  values: [5, 10, 20, 30, 50, 100],
              }"
            >
              <div slot="select-header">
                <CInputCheckbox
                    id="checkAll"
                    label=""
                    class="pb-3"
                    name="listSelected"
                    @update:checked="() => handleCheckAll()"
                    :checked="checkAll"
                />
              </div>
              <td slot="select" slot-scope="{item}">
                <div>
                  <CInputCheckbox
                      id="checkBox"
                      :key="item.id"
                      label=""
                      :value="item.item"
                      name="listSelected"
                      @update:checked="() => handleSelect(item)"
                      :checked="listSelected.includes(item.id)"
                  />
                </div>
              </td>
              <td v-if="screen_key===SCREEN_KEY.LIST_CARDBOARD" slot="id" slot-scope="{item}" class="text-center">
                <div> {{ item.id }}</div>
              </td>
              <td v-else slot="id" slot-scope="{item}" class="text-center">
                {{ item.id }} <CButton @click="() => renderInspectItemCardboard(item)" class="btn btn-info btn-xs">詳細</CButton>
              </td>
              <td slot="shop_name" slot-scope="{item}" >
                <div> {{ item.cardboard_history.to_shop.name }}</div>
              </td>
              <td slot="pick_user_name" slot-scope="{item}">
                <div> {{ item.user.name }}</div>
              </td>
              <td slot="status" slot-scope="{item}">
                <div> {{ item.status_name }}</div>
              </td>
              <td slot="items_count" slot-scope="{item}" class="text-center">
                {{ item.items_count }} <CButton @click="() => renderItemCardboardList(item)" class="btn btn-info btn-xs">詳細</CButton>
              </td>
              <td slot="item_cardboard_uninspected" slot-scope="{item}" class="text-center">
                {{ item.item_cardboard_uninspected }} <CButton @click="() => renderItemUnInspect(item)" class="btn btn-info btn-xs">詳細</CButton>
              </td>
              <td slot="item_cardboard_inspect" slot-scope="{item}" class="text-center">
                {{ item.item_cardboard_inspect }} <CButton @click="() => renderItemInspect(item)" class="btn btn-info btn-xs">詳細</CButton>
              </td>
            </DataTable>
          </div>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import ResourceApi from '../../api/resourceApi';
import DataTable from "@/components/DataTable";
import apiCardboardOther from "@/api/cardboardApi";
import {SCREEN_KEY} from '@/utils/constance';
import {WAREHOUSUE} from "@/utils/constance";
import Vue from "vue";
import Cardboard from "@/views/cardboards/CardboardFields";
import ModalComponent from "@/components/views/ModalComponent";
import ListItemCardBoard from "@/views/cardboards/ListItemCardBoard";


const apiCardboard = new ResourceApi('cardboard');
export default {
  name: 'CardBoards',
  components: {ListItemCardBoard, DataTable, ModalComponent},
  props: ['screen_key'],
  data() {
    return {
      SCREEN_KEY: SCREEN_KEY,
      loading: false,
      checkAll: false,
      listSelected: [],
      name: '',
      email: '',
      userShopId: this.$store.getters.user ? this.$store.getters.user.shop_id : '',
      paginationData: {},
      page: 1,
      perPage: 30,
      query: {page: this.page, per_page: this.perPage, screen_key: this.screen_key, type_data: this.typeDataSelected},
      key: Math.random(),
      optionsPerPage: [10, 20, 30, 40, 50, 100],
      fields: this.screen_key === SCREEN_KEY.LIST_CARDBOARD ? Cardboard.fieldsForList() : Cardboard.fieldsForInspect(),
      activePage: 1,
      cardboards: [],
      filterShippedAt: '',
      filterCreatedAt: '',
      filterDate: '',
      typeDate: 2,
      typeDateOpitons: [
        {label: '登録日', value: 1},
        {label: this.$store.getters.user.shop_id === WAREHOUSUE ? '出荷日' : '返品日', value: 2},
      ],
      statusListOpitons: [
        {label: '未出荷', value: 1},
        {label: '出荷済', value: 2}
      ],
      statusInspectOpitons: [
        // {label: '出荷済', value: 2},
        {label: '受取済', value: 3}
      ],
      // unship or shipped
      statusChange: this.screen_key === SCREEN_KEY.LIST_CARDBOARD ? 1 : 3,
      showItemModal: false,
      screenKeyItem: '',
      cardboardId: null,
      cardHeaderTitle: this.getCardHeaderTitle(),
      cardRegistrationBtn: this.$store.getters.user.shop_id === WAREHOUSUE ? '出荷段ボール登録' : '返品段ボール登録'
    }
  },
  watch: {
    listSelected: function (newVal, oldVal) {
      let checkAll = true;
      this.cardboards.map(s => {
        if (!newVal.includes(s.id)) {
          checkAll = false;
        }
      })
      this.checkAll = checkAll;
    },
    cardboards: function (newVal, oldVal) {
      let checkAll = true;
      this.cardboards.map(s => {
        if (!this.listSelected.includes(s.id)) {
          checkAll = false;
        }
      })
      this.checkAll = checkAll;
    }
  },
  created() {
    this.getData();
  },
  methods: {
    getData(params) {
      // this.resetSelected()
      this.loading = true
      this.key = Math.random()
      this.query = {...this.query, ...params}
      apiCardboard.index(this.query).then(response => {
        this.cardboards = response.data.data;
        this.paginationData = response.data;
        this.loading = false
      }).catch(error => {
        this.loading = false
      })
    },

    handleCheckAll() {
      let listSelected = this.listSelected
      if (this.checkAll) {
        this.cardboards.map(s => {
          listSelected = listSelected.filter(l => s.id !== l)
        })
      } else {
        this.cardboards.map(s => {
          listSelected.push(s.id)
        })
      }
      this.listSelected = listSelected;
    },
    handleSelect(item) {
      if (this.listSelected.includes(item.id)) {
        this.listSelected = this.listSelected.filter(s => s !== item.id)
      } else {
        this.listSelected.push(item.id)
      }
    },
    setDateFilter(e) {
      this.filterDate = new Date(e.target.value).getTime()
    },
    filterData() {
      this.page = 1;
      let params = this.getParams({page: 1});
      this.getData(params);
    },

    getParams(param) {
      let params = {
        created_at_like: this.filterCreatedAt,
        shipped_at_like: this.filterShippedAt
      }
      return {...params, ...param}
    },
    resetFilterData() {
      // this.filterCreatedAt = '';
      // this.filterShippedAt = '';
      this.filterDate = ''
      let params = {created_at_like: '', shipped_at_like: ''}
      this.getData(params);
    },
    selectChange(val) {
      this.statusSelected = val.target.value ? parseInt(val.target.value) : ''
    },
    selectChangePerpage(val) {
      this.perPage = val ? parseInt(val) : ''
      this.getData({per_page: val});
    },

    pageChange(val) {
      this.page = val;
      this.getData({page: val});
    },
    handleQRDownload() {
      this.loading = true;
      let list = this.listSelected;
      let listStr = list.join();
      if (!listStr) {
        Vue.$toast.error('段ボールを選択してください');
        this.loading = false;
        return;
      }

      let params = {list_id_str: listStr}
      apiCardboardOther.generateQR(params).then(response => {
        this.listSelected = []
        Vue.$toast.success('QRコードの印刷に成功しました')
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        let timeCurrent = new Date();
        fileLink.setAttribute('download', 'cardboard-' + timeCurrent.toLocaleString() + '.pdf');
        document.body.appendChild(fileLink);
        fileLink.click();
      }).catch(error => {
        Vue.$toast.error('ダウンロードに失敗しました')
      }).finally(() => {
        this.loading = false
      })
    },

    selectChangeTypeDate(val) {
      this.typeDate = parseInt(val.target.value)
      this.filterDataAuto()
    },

    changeFilterDate(val) {
      this.filterDataAuto();
    },

    filterDataAuto() {
      let params = {created_at_like: '', shipped_at_like: ''}
      this.getData(params)
      if (this.typeDate == 1) {
        this.getData({created_at_like: this.filterDate})
      } else {
        this.getData({shipped_at_like: this.filterDate})
      }
    },

    changeStatusChange(val) {
      this.statusChange = parseInt(val.target.value)
    },

    handleConfirmChangeStatus() {
      this.$confirm(
          {
            title: '確認',
            message: `段ボールの状態を変更しますか？？`,
            button: {
              no: 'いいえ',
              yes: 'はい'
            },
            callback: confirm => {
              if (confirm) {
                // ... do something
                this.changeStatusCardboard()
              }
            }
          }
      )
    },

    changeStatusCardboard() {
      this.loading = true
      let list = this.listSelected
      let listStr = list.join()
      let params = {
        list_id_str: listStr,
        status: this.statusChange,
        screen_key: this.screen_key,
      }
      if (this.statusChange === 2 && this.screen_key === SCREEN_KEY.LIST_CARDBOARD_INSPECT) {
        Vue.$toast.error('入荷予定を出荷済みには変更出来ません')
        this.loading = false
        return
      }

      apiCardboardOther.changeStatusCardboard(params).then(reposense => {
        let data = reposense.data.message
        if (data === "empty") {
          Vue.$toast.error('段ボールを選択してください')
          return
        }
        if (data === "can_not") {
          Vue.$toast.error('店舗が既に段ボールを受け取ったので状態を変更することは出来ません。')
          return
        }
        this.listSelected = [];
        this.resetFilterData();
        // carboard.status = shipped. need create const
        if (this.statusChange === 2) {
          Vue.$toast.success('出荷が完了しました。');
          return;
        }
        Vue.$toast.success('ステータスを変更しました。');
      }).catch(error => {
        Vue.$toast.error('Change Error')
      }).finally(() => {
            this.loading = false
          }
      )
    },

    renderCreateCardboard() {
      this.$router.push({path: `cardboard/create`})
    },
    renderQrCode() {
      this.$router.push({path: `inspect/qr-scan`})
    },

    renderItemUnInspect(item) {
      this.cardboardId = null;
      this.screenKeyItem = null;
      this.screenKeyItem = 'LIST_ITEM_UNINSPECT';
      this.cardboardId = item.id;
      this.showItemModal = true
    },
    renderItemInspect(item) {
      this.cardboardId = null;
      this.screenKeyItem = null;
      this.screenKeyItem = 'LIST_ITEM_INSPECT';
      this.cardboardId = item.id;
      this.showItemModal = true;
    },
    renderItemCardboardList(item) {
      // cardboard.status = shipped and receive
      if (item.status === 2 || item.status === 3) {
        this.cardboardId = null;
        this.screenKeyItem = null;
        this.screenKeyItem = 'LIST_ITEM_ALL';
        this.cardboardId = item.id;
        this.showItemModal = true;
      } else {
        this.$router.push({path: `cardboard/${item.id}`})
      }
    },
    renderInspectItemCardboard(item) {
      this.$router.push({path: `inspect/${item.id}`})
    },
    getCardHeaderTitle() {
      if (this.screen_key === SCREEN_KEY.LIST_CARDBOARD){
        return this.$store.getters.user.shop_id === WAREHOUSUE ? '出荷段ボール一覧' : '返品段ボール一覧';
      }
      return '入荷予定在庫一覧';
    }
  },
}
</script>
<style>
.typeDate > div:first-child, .statusChange > div:first-child {
  flex: 100%;
  max-width: 100%;
}

.statusChange {
  margin: 0px;
}

</style>
