
import {cardboardFieldsLabel} from "@/utils/utils";

const Cardboard = {
    fieldsForList() {
        return [
            {key: 'select', label: ''},
            {key: 'id', label: cardboardFieldsLabel('出荷ID', '返品ID')},
            {key: 'created_at', label: '登録日'},
            {key: 'shipped_at', label: cardboardFieldsLabel('出荷日', '返品日')},
            {key: 'status', label: 'ステータス'},
            {key: 'shop_name', label: cardboardFieldsLabel('出荷先', '返品先')},
            {key: 'pick_user_name', label: cardboardFieldsLabel('出荷担当者', '返品担当者')},
            {key: 'items_count', label: '商品点数'}
        ]
    },
    fieldsForInspect() {
        return [
            {key: 'select', label: ''},
            {key: 'id', label: 'ID'},
            {key: 'created_at', label: '登録日'},
            {key: 'shipped_at', label: cardboardFieldsLabel('出荷日', '返品日')},
            {key: 'status', label: 'ステータス'},
            {key: 'shop_name', label: cardboardFieldsLabel('出荷先', '返品先')},
            {key: 'pick_user_name', label: cardboardFieldsLabel('出荷担当者', '返品担当者')},
            {key: 'item_cardboard_uninspected', label: '未検品数(未検品)'},
            {key: 'item_cardboard_inspect', label: '検品済数(検品済)'}
        ]
    }
}

export default Cardboard