var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('CDataTable',{attrs:{"hover":"","striped":"","border":"","itemsPerPage":200,"items":_vm.itemMasters,"fields":_vm.fields},scopedSlots:_vm._u([{key:"item_id",fn:function(ref){
var item = ref.item;
return _c('td',{},[_c('div',[_vm._v(" "+_vm._s(item.item_id)+" ")])])}},{key:"item_code",fn:function(ref){
var item = ref.item;
return _c('td',{staticClass:"text-center"},[_c('div',[_vm._v(" "+_vm._s(item.item.item_master.item_code)+" ")])])}},{key:"name",fn:function(ref){
var item = ref.item;
return _c('td',{staticClass:"text-center"},[_c('div',[_vm._v(" "+_vm._s(item.item.item_master.name)+" ")])])}},{key:"size",fn:function(ref){
var item = ref.item;
return _c('td',{staticClass:"text-center"},[_c('div',[_vm._v(" "+_vm._s(item.item.item_master.size.name)+" ")])])}},{key:"color",fn:function(ref){
var item = ref.item;
return _c('td',{staticClass:"text-center"},[_c('div',[_vm._v(" "+_vm._s(item.color_name)+" ")])])}},{key:"cost",fn:function(ref){
var item = ref.item;
return _c('td',{staticClass:"text-right"},[_c('div',[_vm._v(" "+_vm._s(item.cost)+" ")])])}},{key:"price",fn:function(ref){
var item = ref.item;
return _c('td',{staticClass:"text-right"},[_c('div',[_vm._v(" "+_vm._s(item.price)+" ")])])}},{key:"retail_price",fn:function(ref){
var item = ref.item;
return _c('td',{staticClass:"text-right"},[_c('div',[_vm._v(" "+_vm._s(item.retail_price)+" ")])])}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }